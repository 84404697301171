
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getProjectDetail, projectLog } from '@/api/index'
const columns = [
  {
    title: '时间',
    dataIndex: 'add_time',
    key: 'add_time'
  },
  {
    title: '备注人',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: '备注内容',
    dataIndex: 'remark',
    key: 'remark'
  }
]
export default defineComponent({
  name: 'projectDetail',
  props: ['id'], // 项目id
  setup (props) {
    const router = useRouter()
    const detailObj = ref({})
    const logs = ref()
    // 跳转详情页面
    const goTeamDetail = (item: any) => {
      router.push({ name: 'teamDetail', params: { project_id: props.id, team_id: item.team_id } })
      sessionStorage.setItem('team_name', item.team)
    }
    // 获取绑定数据
    const getProjectDetailHttp = () => {
      getProjectDetail({ id: props.id }).then(res => {
        detailObj.value = res.data
        console.log(detailObj.value)
      })
    }
    const isShowTime = (strat_time: any) => {
      if (strat_time && strat_time.substring(0, 4) === '0001') {
        console.log(strat_time)
        const a = strat_time.substring(0, 4)
        console.log(a)
        return false
      } else {
        return true
      }
      // const a = strat_time.substring(0, 4)
      // console.log(a)
    }
    const getEndTime = (end_time: any) => {
      return (end_time && end_time.substring(0, 4) === '0001') ? '至今' : end_time
    }
    // 获取日志
    const getProjectLog = () => {
      projectLog({ id: props.id }).then(res => {
        logs.value = res.data
      })
    }
    onMounted(() => {
      getProjectDetailHttp()
      getProjectLog()
    })
    return {
      getEndTime,
      isShowTime,
      detailObj,
      // ...toRef(detailObj),
      goTeamDetail,
      columns,
      logs
    }
  }
})
